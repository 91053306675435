import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Settings() {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userInfo");
    navigate("/login");
  };

  const handleCancelSubscription = () => {
    setShowModal(true);
  };

  const confirmCancelSubscription = () => {
    window.location.href =
      "https://billing.stripe.com/p/login/dR64glfVHbDz2eA3cc";
  };

  const openDisclaimer = () => {
    window.open("/disclaimer", "_blank");
  };

  return (
    <div className="bg-gray-900 min-h-screen p-8 flex flex-col items-center justify-center">
      <button
        onClick={() => navigate("/")}
        className="flex items-center text-orange-400 hover:text-orange-500 font-medium py-2 px-4 rounded mb-8"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
        Back
      </button>

      <h2 className="text-2xl font-semibold text-white mb-4">Settings</h2>

      <div className="bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-xl font-semibold text-orange-400 mb-4 text-center">
          Your Information
        </h2>
        <div className="text-white space-y-2">
          {userInfo ? (
            <>
              <p>
                <strong>Name:</strong> {userInfo.first_name}{" "}
                {userInfo.last_name}
              </p>
              <p>
                <strong>Email:</strong> {userInfo.email}
              </p>
            </>
          ) : (
            <p className="text-red-400">User information not available.</p>
          )}
        </div>
        <div className="flex flex-col items-center mt-6 space-y-4">
          <button
            onClick={handleCancelSubscription}
            className="w-2/3 bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-400 transition-colors"
          >
            Manage Subscription
          </button>
          <button
            onClick={handleLogout}
            className="w-2/3 bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-400 transition-colors"
          >
            Logout
          </button>
        </div>
      </div>

      <div className="bg-gray-800 p-6 rounded-lg shadow-md mt-4 w-full max-w-md text-center">
        <h2 className="text-xl font-semibold text-orange-400 mb-4 mt-4">
          Need Support?
        </h2>
        <p className="text-white">
          If you need support or encounter a bug or another issue, please reach
          out to{" "}
          <a
            href="mailto:jared@askcure.ai"
            className="text-orange-500 hover:underline"
          >
            jared@askcure.ai
          </a>{" "}
          for help.
        </p>
      </div>

      <div className="bg-gray-800 p-6 rounded-lg shadow-md mt-4 w-full max-w-md">
        <button
          onClick={openDisclaimer}
          className="w-2/3 mx-auto bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-400 transition-colors block"
        >
          View Disclaimer
        </button>
      </div>

      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold text-white mb-4">
              Are you sure you want to make changes to your plan?
            </h3>
            <div className="flex space-x-4 justify-center">
              <button
                onClick={confirmCancelSubscription}
                className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-400 transition-colors"
              >
                Yes
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-400 transition-colors"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Settings;
