import React, { useState, useEffect, useRef } from "react";
import SourceCard from "./SourceCard";

const SourceSection = ({
  sources,
  openSourcePopup,
  isCollapsed,
  setIsCollapsed,
  selectedResponseIndex,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const sourcesPerPage = 3; // Keep this constant for consistency
  const sectionRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const totalPages = Math.ceil(sources.length / sourcesPerPage);
  const estimatedCardHeight = 200; // Estimated height of each SourceCard

  // Track if the screen is small
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Detect screen size changes
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleResize = () => setIsSmallScreen(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);
    handleResize(); // Initial check
    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  const goToNextPage = () => {
    const nextPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(nextPage);
    scrollToPage(nextPage);
  };

  const goToPrevPage = () => {
    const prevPage = Math.max(currentPage - 1, 1);
    setCurrentPage(prevPage);
    scrollToPage(prevPage);
  };

  const scrollToPage = (page) => {
    if (scrollContainerRef.current) {
      const scrollPosition = (page - 1) * sourcesPerPage * estimatedCardHeight;
      scrollContainerRef.current.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    // Recalculate currentPage when sources change
    setCurrentPage(1);
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0 });
    }
  }, [sources]);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const scrollTop = scrollContainerRef.current.scrollTop;
      const newPage =
        Math.floor(scrollTop / (sourcesPerPage * estimatedCardHeight)) + 1;
      setCurrentPage(Math.min(newPage, totalPages));
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleOpenSourcePopup = (source) => {
    openSourcePopup(source);
    // Automatically collapse source section on small screens when a popup opens
    if (isSmallScreen) {
      setIsCollapsed(true);
    }
  };

  const formattedIndex = (selectedResponseIndex + 1)
    .toString()
    .padStart(2, "0");

  return (
    <>
      {/* Open Button (Visible when collapsed) */}
      {isCollapsed && (
        <button
          onClick={toggleCollapse}
          className="fixed top-1/2 right-0 transform -translate-y-1/2 z-50 p-3 bg-gray-700 text-white rounded-l-lg shadow-lg hover:bg-gray-600 focus:outline-none"
        >
          <div className="space-y-1">
            <div className="w-6 h-0.5 bg-white"></div>
            <div className="w-6 h-0.5 bg-white"></div>
            <div className="w-6 h-0.5 bg-white"></div>
          </div>
        </button>
      )}
      {/* Source Section (Visible when expanded) */}
      <div
        ref={sectionRef}
        className={`fixed top-0 right-0 z-40 transition-transform duration-300 bg-gray-800 shadow-lg h-screen w-80 ${
          isCollapsed ? "translate-x-full" : "translate-x-0"
        } rounded-l-lg border-l border-gray-700 flex flex-col`}
      >
        <div className="flex justify-between items-center pl-4 pt-4 pr-4">
          <div className="text-lg font-semibold text-white">
            #{formattedIndex}
          </div>
          <h5
            id="drawer-navigation-label"
            className="text-lg font-semibold text-center flex-grow text-white uppercase"
          >
            Sources
          </h5>
          <button
            onClick={toggleCollapse}
            className="text-white p-2 rounded focus:outline-none hover:bg-gray-700"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414
                1.414L11.414 10l4.293 4.293a1 1 0
                01-1.414 1.414L10 11.414l-4.293
                4.293a1 1 0
                01-1.414-1.414L8.586 10
                4.293 5.707a1 1 0
                010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div
          className="p-4 space-y-4 flex-grow overflow-y-auto"
          onScroll={handleScroll}
          ref={scrollContainerRef}
        >
          {sources.map((source, index) => (
            <SourceCard
              key={index}
              title={source.title}
              authors={source.authors}
              publicationDate={source.date_published}
              publicationType={source.publication_types}
              abstract={source.abstract}
              pmid={source.pmid}
              citationCount={source.citation_count}
              openSourcePopup={() => handleOpenSourcePopup(source)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SourceSection;
