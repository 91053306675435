import React from "react";
import SimilarArticlesList from "./SimilarArticlesList";

const SourcePopup = ({
  title,
  authors,
  publicationDate,
  publicationType,
  abstract,
  pmid,
  citationCount,
  isSourceCollapsed,
  closeSourcePopup,
  answer,
  journal,
  highlightedAbstract,
  similarArticles,
  isLoadingAbstract,
  isLoadingSimilar,
  updateSourcePopup,
}) => {
  const pubmedUrl = `https://pubmed.ncbi.nlm.nih.gov/${pmid}/`;

  const generateCitation = () => {
    const pubTypes = Array.isArray(publicationType)
      ? publicationType
      : publicationType
      ? [publicationType]
      : [];
    const publicationTypeString =
      pubTypes.length > 0 ? pubTypes.join(", ") : "N/A";

    return `${authors}. "${title}" ${
      journal ? `<em>${journal}</em>, ` : ""
    }${publicationTypeString}, ${publicationDate}. PubMed, ${pubmedUrl}.`;
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(generateCitation().replace(/\*/g, ""))
      .catch((err) => console.error("Failed to copy citation: ", err));
  };

  const getPopupStyle = () => {
    if (isSourceCollapsed) {
      return {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      };
    } else {
      const sourceSectionWidth = 300;
      const windowWidth = window.innerWidth;
      const popupWidth = 600;
      const newLeft =
        (windowWidth - sourceSectionWidth - popupWidth) / 2 + popupWidth / 2;
      return {
        top: "50%",
        left: `${newLeft}px`,
        transform: "translate(-50%, -50%)",
      };
    }
  };

  // Decide which abstract text to show
  const displayedAbstract = highlightedAbstract || abstract;

  return (
    <div
      style={{
        position: "fixed",
        ...getPopupStyle(),
      }}
    >
      <div className="bg-[#1F2937] text-white w-full sm:max-w-[600px] max-h-[85vh] overflow-y-auto rounded-lg shadow-lg p-6 relative flex flex-col animate-scaleIn">
        <style>
          {`
              @keyframes scaleIn {
                from { transform: scale(0.95); opacity: 0; }
                to { transform: scale(1); opacity: 1; }
              }
              .animate-scaleIn {
                animation: scaleIn 0.3s ease-out forwards;
              }
            `}
        </style>

        <button
          onClick={closeSourcePopup}
          className="absolute top-4 left-4 flex items-center text-white focus:outline-none bg-gray-700 hover:bg-gray-600 rounded-md p-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <span>Close</span>
        </button>

        {/* Title */}
        <div className="mt-12">
          <a
            href={pubmedUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl font-semibold mb-2 hover:underline"
            style={{ color: "#ffc77d" }}
          >
            {title}
          </a>
        </div>

        {/* Journal */}
        {journal && (
          <div className="text-sm text-gray-400 mt-1">
            <strong>Journal:</strong> {journal}
          </div>
        )}

        {/* Citations */}
        <div className="text-sm text-gray-400 flex items-center mt-1">
          <span className="flex items-center bg-gray-800 bg-opacity-80 text-orange-300 px-2 py-0.5 rounded-full">
            Citations: {citationCount}
          </span>
        </div>

        {/* Content Sections */}
        <div className="space-y-4 flex-grow">
          <div className="p-3 bg-gray-700 rounded-lg shadow-inner text-sm">
            <strong>Authors:</strong>
            <p>{authors}</p>
          </div>

          <div className="flex space-x-3">
            <div className="flex-1 p-3 bg-gray-700 rounded-lg shadow-inner text-sm">
              <strong>Publication Date:</strong>
              <p>{publicationDate}</p>
            </div>
            <div className="flex-1 p-3 bg-gray-700 rounded-lg shadow-inner text-sm">
              <strong>Publication Type:</strong>
              <p>
                {Array.isArray(publicationType) && publicationType.length > 0
                  ? publicationType.join(", ")
                  : "N/A"}
              </p>
            </div>
          </div>

          <div className="p-3 bg-gray-700 rounded-lg shadow-inner text-sm relative">
            <strong>Abstract:</strong>
            {/* Show the abstract always */}
            <p dangerouslySetInnerHTML={{ __html: displayedAbstract }}></p>

            {/* Spinner in the top-right corner if loading */}
            {isLoadingAbstract && (
              <div className="absolute top-2 right-2 flex items-center justify-center">
                <svg
                  className="animate-spin h-6 w-6 text-orange-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              </div>
            )}
          </div>

          <div
            className="p-4 bg-gray-700 rounded-lg shadow-inner cursor-pointer hover:bg-gray-600 transition-colors"
            onClick={handleCopy}
          >
            <strong style={{ color: "#ffc77d" }}>Citation (MLA):</strong>
            <div
              className="ml-4 text-sm whitespace-pre-wrap"
              style={{
                lineHeight: "2",
                textIndent: "-40px",
                paddingLeft: "40px",
              }}
            >
              <p>
                {authors}. “{title}.”{" "}
                <span className="italic">{journal ? `${journal}, ` : ""}</span>
              </p>
              <p className="ml-6">
                <span className="italic">
                  {Array.isArray(publicationType) && publicationType.length > 0
                    ? publicationType.join(", ")
                    : "N/A"}
                </span>
                , {publicationDate}. <span className="italic">PubMed</span>,{" "}
                <a href={pubmedUrl} target="_blank" rel="noopener noreferrer">
                  {pubmedUrl}
                </a>
                .
              </p>
            </div>

            <div className="text-xs mt-2 text-gray-400">
              Click to copy citation.
            </div>
          </div>

          <div className="p-3 bg-gray-700 rounded-lg shadow-inner text-sm">
            <strong>Similar Articles:</strong>
            {isLoadingSimilar ? (
              <div className="flex justify-center items-center mt-4">
                <svg
                  className="animate-spin h-8 w-8 text-orange-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              </div>
            ) : similarArticles && similarArticles.length > 0 ? (
              <SimilarArticlesList
                articles={similarArticles}
                updateSourcePopup={updateSourcePopup}
              />
            ) : (
              !isLoadingSimilar && (
                <p className="mt-2 text-gray-400">No similar articles found.</p>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourcePopup;
