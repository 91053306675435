import React from "react";

const SimilarArticleCard = ({ article, updateSourcePopup }) => {
  const handleClick = () => {
    if (updateSourcePopup) {
      updateSourcePopup(article);
    }
  };

  return (
    <div
      className="p-2 bg-gray-800 rounded hover:bg-gray-700 cursor-pointer transition-colors"
      onClick={handleClick}
    >
      <p className="text-sm font-semibold text-orange-400">
        {article.title.length > 60
          ? article.title.substring(0, 60) + "..."
          : article.title}
      </p>
      <p className="text-xs text-gray-300">
        {article.authors.length > 60
          ? article.authors.substring(0, 60) + "..."
          : article.authors}
      </p>
    </div>
  );
};

export default SimilarArticleCard;
