import React from "react";

const Placeholder = () => {
  return (
    <div className="h-full flex items-center justify-center p-4">
      <div className="flex flex-col items-center text-center max-w-md mx-auto">
        <div className="w-24 h-24 rounded-full overflow-hidden p-1">
          <img
            src="assets/orange-just-logo.png"
            alt="Cure AI Logo"
            className="w-full h-full object-contain"
          />
        </div>
        <h1 className="text-2xl font-bold text-white mt-4">
          Cure AI: Scientific Research Engine
        </h1>
        <p className="text-gray-400 italic">
          <strong>Cure AI</strong> is your trusted partner for{" "}
          <strong>scientific research</strong>. Accessing over 26 million PubMed
          articles, we offer <strong>tailored insights</strong> and seamless
          navigation between AI analysis and primary literature.
        </p>
      </div>
    </div>
  );
};

export default Placeholder;
