import React from "react";
import SimilarArticleCard from "./SimilarArticleCard";

const SimilarArticlesList = ({ articles, updateSourcePopup }) => {
  return (
    <div className="mt-2 space-y-2 max-h-60 overflow-y-auto">
      {articles.map((article, index) => (
        <SimilarArticleCard
          key={index}
          article={article}
          updateSourcePopup={updateSourcePopup}
        />
      ))}
    </div>
  );
};

export default SimilarArticlesList;
