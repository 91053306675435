import React from "react";
import { useNavigate } from "react-router-dom";

const Disclaimer = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-900 min-h-screen p-8">
      <button
        onClick={() => navigate("/")}
        className="flex items-center text-orange-400 hover:text-orange-500 font-medium py-2 px-4 rounded mb-8"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
        Back
      </button>
      <h2 className="text-xl font-semibold text-white mb-4 text-center">
        Cure AI Content Disclaimer
      </h2>
      <div className="bg-gray-800 p-6 rounded-lg shadow-md">
        <p className="text-white mb-4">
          <b>
            The following content has been generated by an AI language model.
          </b>{" "}
          Please note that while we strive to provide accurate and up-to-date
          information, the content generated by the AI model may not always
          reflect the most current medical literature, news, events, or
          developments. The AI model has been trained on a vast range of data up
          until January 2024, and it does not have real-time awareness or access
          to current events beyond that date.
        </p>
        <p className="text-white mb-4">
          The AI-generated content is intended to be informative and helpful;
          however, it should not be considered as professional or medical
          advice, a substitute for independent medical research, or a substitute
          for consulting with qualified experts in the relevant field. The
          information provided is based on patterns, trends, and knowledge
          available up until January 2024, and there may be new information or
          developments that could impact the accuracy or applicability of the
          content.
        </p>
        <p className="text-white mb-4">
          We encourage users to independently verify and cross-reference the
          information provided by the AI model with other reliable sources
          before making any decisions or taking any actions based on the
          content. The AI-generated content should be used as a starting point
          for research and exploration, but it is essential to exercise
          independent critical thinking and judgment when interpreting and
          applying the information.
        </p>
        <p className="text-white mb-4">
          As an AI language model, the content generated is based on the data it
          has been trained on, which may include biased or incomplete
          information. We strive to mitigate biases, but it is important to be
          aware of potential limitations and to seek alternative perspectives
          when necessary.
        </p>
        <p className="text-white mb-4">
          We are continually working to improve our AI model and the accuracy of
          the content it generates. However, we cannot guarantee the complete
          absence of errors or inaccuracies. Therefore, we disclaim any
          liability for any damages or losses incurred as a result of using or
          relying on AI-generated content.
        </p>
        <p className="text-white mb-4">
          By accessing and using the AI-generated content, you acknowledge that
          you have read, understood, and agreed to the terms of this content
          disclaimer.
        </p>
        <p className="text-white mb-4">
          Please contact us if you have any questions or concerns regarding the
          AI-generated content.
        </p>
        <p className="text-white">
          <b>Cure AI Team</b>
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
