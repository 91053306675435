// Login.js
import React, { useState, useEffect } from "react";
import { Typography } from "@material-tailwind/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoadingSpinner = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <div className="flex justify-center space-x-2">
      <span className="circle animate-loader"></span>
      <span className="circle animate-loader animation-delay-200"></span>
      <span className="circle animate-loader animation-delay-400"></span>
    </div>
    <div className="loading-text" style={{ marginTop: "20px" }}>
      <span className="typing-text">Logging In</span>
      <span className="cursor">|</span>
    </div>
  </div>
);

async function checkUserAccess(email) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_USERS_API}/check_access/${email}`
    );
    return response.data;
  } catch (error) {
    console.error("User access check error:", error);
    throw error;
  }
}

function Login({ authenticateUser }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    if (isLoggedIn) {
      authenticateUser(true);
      // navigate("/");
    }
  }, [navigate, authenticateUser]);

  const fetchUserInfo = async (email) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USERS_API}/get_user_info/${encodeURIComponent(
          email
        )}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching user info:", error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      // Authenticate user
      const authResponse = await axios.post(
        `${process.env.REACT_APP_USERS_API}/auth`,
        { email, password }
      );
      console.log(authResponse.data);

      // Check user access
      const accessData = await checkUserAccess(email);
      if (accessData.access) {
        authenticateUser(true);
        localStorage.setItem("isLoggedIn", "true"); // Ensure it's a string
        localStorage.setItem("userEmail", email); // Store the user's email

        const userInfo = await fetchUserInfo(email);
        localStorage.setItem("userInfo", JSON.stringify(userInfo));

        navigate("/"); // Use navigate instead of window.location.href
      } else if (
        accessData.message.includes("Customer does not exist in Stripe")
      ) {
        window.location.href = `https://www.askcureai.com/select-plan?email=${encodeURIComponent(
          email
        )}`;
      } else {
        throw new Error(`Access denied. ${accessData.message}`);
      }
    } catch (error) {
      console.error("Login failed:", error.message || error);
      let errorMessage = "Login failed. Please check your credentials.";
      if (error.response && error.response.data && error.response.data.detail) {
        errorMessage = error.response.data.detail;
        if (errorMessage.includes("User does not exist")) {
          errorMessage = `User ${email} does not exist.`;
        } else if (errorMessage.includes("Invalid password")) {
          errorMessage = `Password does not match the email ${email}.`;
        } else if (errorMessage.includes("Customer does not exist in Stripe")) {
          errorMessage = `Customer ${email} does not exist in Stripe. Please <a href="https://www.askcureai.com/select-plan" class="text-blue-500 underline">configure your account with Stripe</a>.`;
        } else if (
          errorMessage.includes("No active or trialing subscription found")
        ) {
          errorMessage = `No active or trialing subscription found for ${email}. Please <a href="https://billing.stripe.com/p/login/dR64glfVHbDz2eA3cc" class="text-blue-500 underline">pay for your account</a>.`;
        }
      } else {
        errorMessage = "Internal Server Error. Please try again later.";
      }
      setError(errorMessage);
      authenticateUser(false); // Ensure isLoggedIn is set to false
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="flex justify-center items-center min-h-screen bg-gray-900">
      <div className="w-full max-w-lg">
        <div className="bg-gray-800 rounded-lg shadow-xl p-8">
          <Typography variant="h4" className="text-center text-white">
            Login
          </Typography>
          <Typography color="white" className="mt-1 font-light text-center">
            Welcome back! Please enter your details.
          </Typography>

          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Error: </strong>
              <span
                className="block sm:inline"
                dangerouslySetInnerHTML={{ __html: error }}
              ></span>
            </div>
          )}

          <form onSubmit={handleSubmit} className="mt-8 mb-2 space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-white"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border sm:text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5 bg-gray-700 border-gray-600 text-white"
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-white"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border sm:text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5 bg-gray-700 border-gray-600 text-white"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full text-white bg-gradient-to-r from-orange-400 to-red-600 hover:bg-gradient-to-l focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-orange-800"
            >
              Log In
            </button>
            <div className="text-center">
              <Typography color="white" className="font-light">
                Don't have an account?{" "}
                <a
                  href="https://www.askcureai.com/signup"
                  className="font-medium text-orange-600 hover:text-orange-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign Up
                </a>
              </Typography>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
