import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Modal from "react-modal";
import "./App.css";
import Controller from "./Controller";
import Output from "./Output";
import SourceSection from "./SourceSection";
import SourcePopup from "./SourcePopup";
import Header from "./Header";
import Placeholder from "./Placeholder";
import Login from "./Login";
import Settings from "./Settings";
import Disclaimer from "./Disclaimer";
import axios from "axios";
import { Analytics } from "@vercel/analytics/react";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [qaList, setQaList] = useState([]);
  const [selectedResponseIndex, setSelectedResponseIndex] = useState(null);
  const [isSourcePopupOpen, setIsSourcePopupOpen] = useState(false);
  const [sourcePopupInfo, setSourcePopupInfo] = useState(null);
  const [isSourceCollapsed, setIsSourceCollapsed] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isError, setIsError] = useState(false);

  const controllerRef = useRef();
  const isSmallScreen = windowWidth < 768;

  const [highlightedAbstractCache, setHighlightedAbstractCache] = useState({});
  const [similarArticlesCache, setSimilarArticlesCache] = useState({});

  const checkUserAccess = useCallback(async (email) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USERS_API}/check_access/${email}`
      );
      return response.data;
    } catch (error) {
      console.error("User access check error:", error);
      throw error;
    }
  }, []);

  useEffect(() => {
    const fetchUserInfoAndCheckAccess = async () => {
      try {
        if (isAuthenticated && !userInfo) {
          const userEmail = localStorage.getItem("userEmail");
          if (userEmail) {
            if (!localStorage.getItem("userInfo")) {
              const response = await axios.get(
                `${
                  process.env.REACT_APP_USERS_API
                }/get_user_info/${encodeURIComponent(userEmail)}`
              );
              localStorage.setItem("userInfo", JSON.stringify(response.data));
              setUserInfo(response.data);
            }

            const accessData = await checkUserAccess(userEmail);
            if (!accessData.access) {
              localStorage.setItem("isLoggedIn", "false");
              setIsAuthenticated(false);
              navigate("/login", { replace: true });
            }
          }
        }
      } catch (error) {
        console.error("Error on initial load:", error);
        setIsError(true);
      }
    };

    fetchUserInfoAndCheckAccess();
  }, [isAuthenticated, userInfo, checkUserAccess, navigate]);

  const handleNewQuestion = (question, tags) => {
    const newResponseIndex = qaList.length;
    setQaList((prevList) => [
      ...prevList,
      { question: question, answer: "", sources: [], tags: tags },
    ]);
    setSelectedResponseIndex(newResponseIndex);

    setIsError(false);
    return newResponseIndex;
  };

  const updateResponse = (index, data, isSource = false) => {
    setQaList((prevList) => {
      const newList = [...prevList];
      if (isSource) {
        newList[index] = { ...newList[index], sources: data };
      } else {
        newList[index] = {
          ...newList[index],
          answer: newList[index].answer + data,
        };
      }
      return newList;
    });
  };

  const fetchHighlightedAbstract = async (
    pmid,
    answer,
    abstract,
    originalPmid
  ) => {
    // Only fetch highlights if pmid == originalPmid
    if (pmid !== originalPmid || !answer || !abstract) {
      return abstract; // No highlighting if it's not the original article
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/get_highlight_phrases`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ answer, abstract }),
        }
      );
      const data = await response.json();
      const phrases = data.phrases || [];
      let newAbstract = abstract;
      phrases.forEach((phrase) => {
        if (!phrase) return;
        const escapedPhrase = phrase.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
        const regex = new RegExp(escapedPhrase, "gi");
        newAbstract = newAbstract.replace(
          regex,
          (match) => `<mark class="mark-custom mark-rounded">${match}</mark>`
        );
      });
      return newAbstract;
    } catch (error) {
      console.error("Error fetching highlight phrases:", error);
      return abstract; // fallback
    }
  };

  const fetchSimilarArticles = async (pmid) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/similar_articles/${pmid}?retmax=10`
      );
      const data = await response.json();
      return data.similar_articles || [];
    } catch (error) {
      console.error("Error fetching similar articles:", error);
      return [];
    }
  };

  const ensureDataForPopup = async (pmid, answer, abstract, originalPmid) => {
    let highlightedAbstract = highlightedAbstractCache[pmid];
    let similarArticles = similarArticlesCache[pmid];

    // Only fetch highlight if this is the original pmid used to answer the question
    if (!highlightedAbstract) {
      highlightedAbstract = await fetchHighlightedAbstract(
        pmid,
        answer,
        abstract,
        originalPmid
      );
      if (highlightedAbstract) {
        setHighlightedAbstractCache((prev) => ({
          ...prev,
          [pmid]: highlightedAbstract,
        }));
      }
    }

    if (!similarArticles) {
      similarArticles = await fetchSimilarArticles(pmid);
      if (similarArticles) {
        setSimilarArticlesCache((prev) => ({
          ...prev,
          [pmid]: similarArticles,
        }));
      }
    }

    return { highlightedAbstract, similarArticles };
  };

  const openSourcePopup = (sourceInfo) => {
    const currentAnswer =
      selectedResponseIndex !== null
        ? qaList[selectedResponseIndex].answer
        : "";

    const originalPmid = sourceInfo.pmid; // Mark this as the original article

    setIsSourcePopupOpen(true);
    setSourcePopupInfo({
      title: sourceInfo.title,
      authors: sourceInfo.authors,
      publicationDate: sourceInfo.date_published,
      publicationType: sourceInfo.publication_types,
      abstract: sourceInfo.abstract,
      pmid: sourceInfo.pmid,
      citationCount: sourceInfo.citation_count,
      answer: currentAnswer,
      journal: sourceInfo.journal,
      highlightedAbstract: null,
      similarArticles: null,
      isLoadingAbstract: true,
      isLoadingSimilar: true,
      originalPmid, // store original pmid
    });

    ensureDataForPopup(
      sourceInfo.pmid,
      currentAnswer,
      sourceInfo.abstract,
      originalPmid
    ).then(({ highlightedAbstract, similarArticles }) => {
      setSourcePopupInfo((prev) => ({
        ...prev,
        highlightedAbstract: highlightedAbstract || prev.abstract,
        similarArticles: similarArticles || [],
        isLoadingAbstract: false,
        isLoadingSimilar: false,
      }));
    });
  };

  const closeSourcePopup = () => {
    setIsSourcePopupOpen(false);
    setSourcePopupInfo(null);
  };

  const updateSourcePopupFromChild = (newArticle) => {
    // Immediately update popup for new article
    setSourcePopupInfo((prev) => ({
      ...prev,
      title: newArticle.title,
      authors: newArticle.authors,
      publicationDate: newArticle.date_published,
      publicationType: Array.isArray(newArticle.publication_type)
        ? newArticle.publication_type
        : newArticle.publication_type
        ? [newArticle.publication_type]
        : [],
      abstract: newArticle.abstract,
      pmid: newArticle.pmid,
      citationCount: newArticle.citation_count,
      journal: newArticle.journal,
      highlightedAbstract: null,
      similarArticles: null,
      isLoadingAbstract: true,
      isLoadingSimilar: true,
    }));

    // Fetch data but note: newArticle is a similar article and not the original pmid
    // We use prev.originalPmid to check if highlight is needed.
    const originalPmid = sourcePopupInfo.originalPmid;

    ensureDataForPopup(
      newArticle.pmid,
      sourcePopupInfo.answer,
      newArticle.abstract,
      originalPmid
    ).then(({ highlightedAbstract, similarArticles }) => {
      setSourcePopupInfo((prev) => ({
        ...prev,
        // If pmid != originalPmid, highlightedAbstract will just be the original abstract
        highlightedAbstract: highlightedAbstract || prev.abstract,
        similarArticles: similarArticles || [],
        isLoadingAbstract: false,
        isLoadingSimilar: false,
      }));
    });
  };

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (isAuthenticated && !localStorage.getItem("userInfo")) {
          const userEmail = localStorage.getItem("userEmail");
          if (userEmail) {
            const response = await axios.get(
              `${
                process.env.REACT_APP_USERS_API
              }/get_user_info/${encodeURIComponent(userEmail)}`
            );
            localStorage.setItem("userInfo", JSON.stringify(response.data));
            setUserInfo(response.data);
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
        setIsError(true);
      }
    };
    fetchUserInfo();
  }, [isAuthenticated]);

  const authenticateUser = (isAuth) => {
    setIsAuthenticated(isAuth);
    localStorage.setItem("isLoggedIn", isAuth ? "true" : "false");
    if (!isAuth) {
      localStorage.removeItem("userEmail");
      localStorage.removeItem("userInfo");
    }
  };

  const location = useLocation();
  const hideControllerRoutes = ["/settings", "/login", "/disclaimer"];
  const isControllerVisible =
    isAuthenticated && !hideControllerRoutes.includes(location.pathname);

  const hideController =
    (isSmallScreen && !isSourceCollapsed) || isSourcePopupOpen;

  const redoQuestion = (index) => {
    const question = qaList[index].question;
    setQaList((prevList) => {
      const newList = [...prevList];
      newList[index].answer = "";
      newList[index].sources = [];
      return newList;
    });
    if (controllerRef.current) {
      controllerRef.current.fetchAnswer(question, index);
    }
  };

  return (
    <div className="App bg-gray-900 min-h-screen flex flex-col">
      <Header />
      <main className="flex-1 flex">
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <div className="flex flex-1">
                  <div
                    className={`flex flex-col w-full p-4 transition-width duration-300 space-y-4 ${
                      isSourceCollapsed ? "" : "md:w-3/4"
                    }`}
                  >
                    {qaList.length === 0 ? (
                      <div className="flex-1 flex flex-col justify-center items-center mb-20">
                        <Placeholder />
                      </div>
                    ) : (
                      <>
                        {qaList.map((qa, index) => (
                          <div key={index} className="mb-4">
                            <Output
                              question={qa.question}
                              answer={qa.answer}
                              index={index}
                              setSelectedResponseIndex={
                                setSelectedResponseIndex
                              }
                              redoQuestion={redoQuestion}
                              tags={qa.tags}
                            />
                          </div>
                        ))}
                        <div style={{ height: "100px" }} />
                      </>
                    )}
                  </div>
                  <SourceSection
                    sources={
                      selectedResponseIndex !== null
                        ? qaList[selectedResponseIndex].sources
                        : []
                    }
                    openSourcePopup={openSourcePopup}
                    isCollapsed={isSourceCollapsed}
                    setIsCollapsed={setIsSourceCollapsed}
                    selectedResponseIndex={selectedResponseIndex}
                  />
                </div>
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <div className="flex justify-center items-center min-h-screen w-full">
                  <Settings authenticateUser={authenticateUser} />
                </div>
              </ProtectedRoute>
            }
          />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to="/" replace />
              ) : (
                <div className="flex justify-center items-center min-h-screen w-full">
                  <Login authenticateUser={authenticateUser} />
                </div>
              )
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>

      {!hideController && isControllerVisible && (
        <Controller
          ref={controllerRef}
          handleNewQuestion={handleNewQuestion}
          updateResponse={updateResponse}
          isSourceCollapsed={isSourceCollapsed}
        />
      )}

      <Modal
        isOpen={isSourcePopupOpen}
        onRequestClose={closeSourcePopup}
        className="outline-none"
        overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center"
        ariaHideApp={false}
      >
        {sourcePopupInfo && (
          <SourcePopup
            {...sourcePopupInfo}
            closeSourcePopup={closeSourcePopup}
            isSourceCollapsed={isSourceCollapsed}
            updateSourcePopup={updateSourcePopupFromChild}
          />
        )}
      </Modal>
      <Analytics />
    </div>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
