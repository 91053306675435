import React, { useEffect, useRef, useState } from "react";
import journalsList from "./PubmedInfo.json";

const AdvancedSearchPopup = ({
  isOpen,
  togglePopup,
  startYearInput,
  setStartYearInput,
  endYearInput,
  setEndYearInput,
  selectedJournals,
  setSelectedJournals,
  isAuto,
  setIsAuto,
}) => {
  const [journalInput, setJournalInput] = useState("");
  const [filteredJournals, setFilteredJournals] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [journalInputFocused, setJournalInputFocused] = useState(false);
  const [isMouseInside, setIsMouseInside] = useState(false);

  const journalListRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        togglePopup();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, togglePopup]);

  const handleMouseEnter = () => {
    setIsMouseInside(true);
  };

  const handleMouseLeave = () => {
    setIsMouseInside(false);
  };

  const handleAutoToggle = () => {
    setIsAuto((prev) => {
      const newAutoState = !prev;
      if (newAutoState) {
        // Reset other search parameters to default
        setStartYearInput("");
        setEndYearInput("");
        setSelectedJournals([]);
      }
      return newAutoState;
    });
  };

  const currentYear = new Date().getFullYear();

  const isNumeric = (value) => /^\d*$/.test(value);

  const handleStartYearChange = (e) => {
    const value = e.target.value;
    if (isNumeric(value) || value === "") {
      setStartYearInput(value);
    }
  };

  const handleEndYearChange = (e) => {
    const value = e.target.value;
    if (isNumeric(value) || value === "") {
      setEndYearInput(value);
    }
  };

  const validateYear = (yearInput, setYearInput, min, max) => {
    if (yearInput !== "") {
      let year = parseInt(yearInput, 10);
      year = isNaN(year) ? min : Math.min(Math.max(year, min), max);
      setYearInput(year.toString());
    }
  };

  const handleStartYearBlur = () =>
    validateYear(startYearInput, setStartYearInput, 1950, currentYear);
  const handleEndYearBlur = () =>
    validateYear(
      endYearInput,
      setEndYearInput,
      startYearInput || 1950,
      currentYear
    );

  // Handle journal input changes
  const handleJournalInputChange = (e) => {
    const value = e.target.value;
    setJournalInput(value);

    const lowerCaseValue = value.toLowerCase();

    const filtered = journalsList
      .filter(
        (journal) =>
          journal.toLowerCase().includes(lowerCaseValue) &&
          !selectedJournals.includes(journal)
      )
      .sort((a, b) => {
        const aStarts = a.toLowerCase().startsWith(lowerCaseValue) ? 0 : 1;
        const bStarts = b.toLowerCase().startsWith(lowerCaseValue) ? 0 : 1;
        if (aStarts !== bStarts) return aStarts - bStarts;
        else return a.localeCompare(b);
      });

    setFilteredJournals(filtered);
    setHighlightedIndex(-1);
  };

  // Handle selecting a journal
  const handleJournalSelect = (journal) => {
    if (!selectedJournals.includes(journal)) {
      setSelectedJournals([...selectedJournals, journal]);
    }
    setJournalInput("");
    setFilteredJournals([]);
    setHighlightedIndex(-1);
  };

  // Handle key events in journal input
  const handleJournalKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => {
        const newIndex =
          prevIndex + 1 >= filteredJournals.length ? 0 : prevIndex + 1;
        scrollToHighlightedItem(newIndex);
        return newIndex;
      });
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => {
        const newIndex =
          prevIndex <= 0 ? filteredJournals.length - 1 : prevIndex - 1;
        scrollToHighlightedItem(newIndex);
        return newIndex;
      });
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (highlightedIndex >= 0 && filteredJournals.length > 0) {
        handleJournalSelect(filteredJournals[highlightedIndex]);
      } else if (filteredJournals.length > 0) {
        handleJournalSelect(filteredJournals[0]);
      }
    }
  };

  const scrollToHighlightedItem = (index) => {
    if (journalListRef.current) {
      const listItem = journalListRef.current.children[index];
      if (listItem) {
        listItem.scrollIntoView({
          block: "nearest",
        });
      }
    }
  };

  // Remove selected journal
  const removeJournal = (journalToRemove) => {
    setSelectedJournals(
      selectedJournals.filter((journal) => journal !== journalToRemove)
    );
  };

  // Handle journal input focus
  const handleJournalInputFocus = () => {
    setJournalInputFocused(true);
    if (journalInput) {
      const lowerCaseValue = journalInput.toLowerCase();
      const filtered = journalsList
        .filter(
          (journal) =>
            journal.toLowerCase().includes(lowerCaseValue) &&
            !selectedJournals.includes(journal)
        )
        .sort((a, b) => {
          const aStarts = a.toLowerCase().startsWith(lowerCaseValue) ? 0 : 1;
          const bStarts = b.toLowerCase().startsWith(lowerCaseValue) ? 0 : 1;
          if (aStarts !== bStarts) return aStarts - bStarts;
          else return a.localeCompare(b);
        });

      setFilteredJournals(filtered);
    }
  };

  const handleJournalInputBlur = () => {
    setJournalInputFocused(false);
    setFilteredJournals([]);
  };

  return (
    <>
      {isOpen && (
        <div
          className="advanced-search-popup absolute bottom-14 left-0 bg-gray-800 p-6 rounded-lg shadow-2xl z-50 w-full max-w-lg transition-opacity duration-300"
          style={{
            backgroundColor: "#1f1f2f",
            opacity: isMouseInside ? "1" : "0.6", // transparency value for popup
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-semibold text-white">
              Advanced Search
            </h3>
            <button
              onClick={togglePopup}
              className="text-white hover:text-gray-400 focus:outline-none"
            >
              ✕
            </button>
          </div>
          <div className="flex">
            {/* Left Side: Start Year and End Year */}
            <div className="flex flex-col space-y-6 w-1/3">
              {/* Start Year Input */}
              <div>
                <label className="block text-sm text-gray-300 mb-1">
                  Start Year
                </label>
                <div className="flex items-center">
                  <input
                    type="text"
                    value={startYearInput}
                    onChange={handleStartYearChange}
                    onBlur={handleStartYearBlur}
                    placeholder="e.g., 2000"
                    className={`text-center w-full h-8 bg-gray-700 text-white border border-gray-600 focus:outline-none ${
                      isAuto ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isAuto}
                  />
                </div>
              </div>

              {/* End Year Input */}
              <div>
                <label className="block text-sm text-gray-300 mb-1">
                  End Year
                </label>
                <div className="flex items-center">
                  <input
                    type="text"
                    value={endYearInput}
                    onChange={handleEndYearChange}
                    onBlur={handleEndYearBlur}
                    placeholder="e.g., 2024"
                    className={`text-center w-full h-8 bg-gray-700 text-white border border-gray-600 focus:outline-none ${
                      isAuto ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isAuto}
                  />
                </div>
              </div>
            </div>

            {/* Divider */}
            <div
              className="my-12 border-l-2 bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400"
              style={{
                width: "1px",
                alignSelf: "stretch",
                margin: "0 24px",
                backgroundImage:
                  "linear-gradient(to right, transparent, #6b7280, transparent)",
              }}
            ></div>

            {/* Right Side: Journals Input */}
            <div className="flex-1">
              <label className="block text-sm text-gray-300 mb-1">
                Journals
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={journalInput}
                  onChange={handleJournalInputChange}
                  onKeyDown={handleJournalKeyDown}
                  placeholder="Type to search journals"
                  className={`w-full pl-10 p-2 h-10 bg-gray-700 text-white rounded-md focus:outline-none ${
                    isAuto ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onFocus={handleJournalInputFocus}
                  onBlur={handleJournalInputBlur}
                  disabled={isAuto}
                />
                <svg
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
                {journalInputFocused &&
                  filteredJournals.length > 0 &&
                  !isAuto && (
                    <ul
                      className="absolute left-0 right-0 mt-1 max-h-40 overflow-y-auto bg-gray-800 border border-gray-600 rounded-md shadow-lg z-10"
                      style={{ maxHeight: "200px", overflowY: "auto" }}
                      ref={journalListRef}
                    >
                      {filteredJournals.map((journal, index) => (
                        <li
                          key={index}
                          onMouseDown={() => handleJournalSelect(journal)}
                          className={`p-2 cursor-pointer text-sm text-white hover:bg-gray-600 ${
                            index === highlightedIndex ? "bg-gray-700" : ""
                          }`}
                          onMouseEnter={() => setHighlightedIndex(index)}
                          onMouseLeave={() => setHighlightedIndex(-1)}
                        >
                          {journal}
                        </li>
                      ))}
                    </ul>
                  )}
              </div>

              {/* Selected Journals Display */}
              {selectedJournals.length > 0 && (
                <div className="flex flex-wrap mt-3 gap-2">
                  {selectedJournals.map((journal, index) => (
                    <span
                      key={index}
                      className="flex items-center text-xs font-medium px-3 py-1 bg-blue-900 text-blue-300 rounded-md"
                    >
                      {journal}
                      <button
                        onClick={() => removeJournal(journal)}
                        className="ml-2 text-gray-400 hover:text-white focus:outline-none"
                      >
                        ×
                      </button>
                    </span>
                  ))}
                </div>
              )}

              {/* Toggle for Auto */}
              <div className="mt-4 flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="auto-toggle"
                    type="checkbox"
                    checked={isAuto}
                    onChange={handleAutoToggle}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="auto-toggle"
                    className="font-medium text-gray-900 dark:text-gray-300"
                  >
                    Auto
                  </label>
                  <p className="text-xs font-normal text-gray-500 dark:text-gray-300">
                    Automatically select high quality journals based on your
                    query.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdvancedSearchPopup;
